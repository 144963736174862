import { React, useEffect, useState } from 'react';
import './index.css'
import { Loader } from '@mantine/core';
function Login() {


    return (
        <div className='container mx-auto w-[80%]'>
            <h1 className='text-3xl'> Pagina non trovata </h1>
        </div>
    );
}

export default Login;